<template>
    <div id="gatewaysList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="gatewaysList"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="gtw_bleGatewaysListSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper" v-if="!isEmptyList">
                                    <div class="dropdown displayInlineBlock">
                                        <button
                                            class="btn btn-bold btn-success btn-sm dropdown-toggle"
                                            type="button"
                                            id="gateways_importGatewaysDropDown"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i class="fa fa-file-excel kt-margin-t-1 kt-margin-r-5"></i>
                                            {{ $t("common_importFrom") }}
                                        </button>
                                        <div
                                            class="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                            style
                                        >
                                            <a
                                                class="dropdown-item"
                                                id="gateways_importGatewaysFromXLSButton"
                                                @click="openImportGatewaysTemplateXlsModal"
                                                href="#"
                                            >
                                                <i class="la la-file-excel-o"></i>
                                                {{ $t("gtw_fromLocalTemplateFile") }}
                                            </a>
                                            <a
                                                class="dropdown-item"
                                                id="gateways_importGatewaysFromXLSOVButton"
                                                @click="openImportGatewaysOVXlsModal"
                                                href="#"
                                            >
                                                <img src="/assets/ale/ov.png" class="ovIcon" />
                                                {{ $t("gtw_fromOmnivistaFile") }}
                                            </a>
                                        </div>
                                    </div>
                                    <span class="kt-margin-l-10">{{ $t("common_or") }}</span>
                                    <router-link
                                        :to="{ name: 'newGateway' }"
                                        id="gateways_createNewGatewayButton"
                                        class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10"
                                    >
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("gtw_createNewBleGateway") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div
                                        class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile"
                                    >
                                        <div
                                            class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm"
                                        >
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <rect
                                                                x="0"
                                                                y="0"
                                                                width="24"
                                                                height="24"
                                                            />
                                                            <path
                                                                d="M19.366142,13.9305937 L17.2619853,15.6656848 C15.9733542,14.1029531 14.0626842,13.1818182 11.9984835,13.1818182 C9.94104045,13.1818182 8.03600715,14.0968752 6.74725784,15.6508398 L4.64798148,13.9098472 C6.44949126,11.7375997 9.12064835,10.4545455 11.9984835,10.4545455 C14.8857906,10.4545455 17.5648042,11.7460992 19.366142,13.9305937 Z M23.5459782,10.4257575 L21.4473503,12.1675316 C19.1284914,9.37358605 15.6994058,7.72727273 11.9984835,7.72727273 C8.30267753,7.72727273 4.87785708,9.36900008 2.55893241,12.1563207 L0.462362714,10.4120696 C3.29407133,7.00838857 7.48378666,5 11.9984835,5 C16.519438,5 20.7143528,7.01399004 23.5459782,10.4257575 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                            />
                                                            <path
                                                                d="M15.1189503,17.3544974 L13.0392442,19.1188213 L11.9619232,20 L10.9331836,19.1485815 L8.80489611,17.4431757 C9.57552634,16.4814558 10.741377,15.9090909 11.9984835,15.9090909 C13.215079,15.9090909 14.347452,16.4450896 15.1189503,17.3544974 Z"
                                                                id="Oval-21"
                                                                fill="#000000"
                                                            />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3
                                                    class="kt-portlet__head-title"
                                                >{{ $t("gtw_bleGatewaysList") }}</h3>
                                            </div>
                                            <div
                                                v-if="!isEmptyList"
                                                class="kt-portlet__head-toolbar"
                                            >
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div
                                                                class="col-md-12 kt-margin-b-20-tablet-and-mobile"
                                                            >
                                                                <div
                                                                    class="kt-input-icon kt-input-icon--left"
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        :placeholder="$t('common_searchByNameOrMacAddress')"
                                                                        id="gateways_searchInput"
                                                                        class="searchInputDatatable form-control form-control-sm width270"
                                                                        @keyup="onSearch" v-model="searchedValue"
                                                                    />
                                                                    <span
                                                                        class="kt-input-icon__icon kt-input-icon__icon--left"
                                                                    >
                                                                        <span>
                                                                            <i class="la la-search"></i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button @click="exportGatewaysModal" id="gateways_exportTagsFromXLSButton" class="exportXLSXButton btn btn-sm btn-outline-success kt-margin-l-10">
                                                    <i class="fa fa-file-excel kt-margin-r-5"></i>
                                                    {{ $t("common_exportFromXLSFile") }}
                                                </button>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist
                                            targetObjectName="GATEWAY"
                                            mainTextKey="gtw_notFound"
                                            subTextKey="gtw_notFoundSubLabel"
                                            imgName="empty_gateway.svg"
                                            v-bind:isDisplayed="isEmptyList"
                                        ></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="bleGateways_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>
                    <!-- begin:: modal -->
                    <app-importgatewaysfromXLSmodal ref="importGatewaysModal"></app-importgatewaysfromXLSmodal>
                    <app-importgatewaysfromOVmodal ref="importGatewaysFromOVModal"></app-importgatewaysfromOVmodal>
                    <!-- end:: modal -->

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import ImportGatewaysModal from "../modals/importgatewaysmodal.vue";
import ImportGatewaysFromOVModal from "../modals/importgatewaysfromovmodal.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteBLEGatewaysTable: null,
            siteBLEGatewaysData: null,
            isEmptyList: false,
            searchedValue: this.$route.params.search || "" // Init the search value if route has params to research specific gateway
        };
    },
    created: function() {
        console.log("Component(Gateways)::created() - called");
        if (this.createAnotherGateway) {
            this.$router.push({ name: "newGateway" });
        } else {
            this.getSiteById(this.siteId);
            this.getSiteBLEGateways(this.siteId);
        }
    },
    mounted: function() {
        console.log("Component(Gateways)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(Gateways)::destroyed() - called");
        // this.resetGatewaysState();
    },
    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        this.resetGatewaysState();
        next();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteBLEGateways: {
            handler() {
                console.log("Component(Gateways)::watch(siteBLEGateways) called");
                this.siteBLEGatewaysData = this.buildDatatableData();
                this.initKtDataTable(this.siteBLEGatewaysData);
                // If the search value is initialized with specific value load the table with this value in search
                if(this.searchedValue){
                    setTimeout(() => {
                        this.onSearch();
                    }, 200)
                }
            },
            deep: true
        },
        user: function(user){
            if (user) {
                console.log("Component(Gateways)::watch(user) called with :", user);
                this.siteBLEGatewaysData = this.buildDatatableData();
                this.initKtDataTable(this.siteBLEGatewaysData);
            }
        },
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteBLEGateways", "currentSite", "createAnotherGateway", "user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteBLEGateways", "getSiteById", "showDeleteModal", "showImportGatewaysModal", "showImportGatewaysFromOVModal", "resetGatewaysState"]),

        onSearch() {
            // By default, search is made by the datatable option: search: {input: $("#gateways_searchInput")}
            if (this.siteBLEGatewaysTable) {
                // Search the same string in all columns: PB no result
                //this.siteBLEGatewaysTable.search(this.searchedValue);
                // Search the same string in ALL columns
                //this.siteBLEGatewaysTable.search(this.searchedValue, ["name", "bleMacAddress", "location"]);

                const searchedValueUpperCase = this.searchedValue.toUpperCase();
                // To search EITHER by name OR by bleMacAddress:
                // 1. Search in "name" field of gateways data
                const dataFoundByName = _.filter(this.siteBLEGatewaysData, gw => gw && gw.name && gw.name.toUpperCase().includes(searchedValueUpperCase));
                // 2. Search in "bleMacAddress" field of gateways data
                const dataFoundByMacAddress = _.filter(this.siteBLEGatewaysData, gw => gw && gw.bleMacAddress && gw.bleMacAddress.toUpperCase().includes(searchedValueUpperCase));
                // 3. Create an array with previous unique resulting searched
                const dataFound = _.unionBy(dataFoundByName, dataFoundByMacAddress, 'id');
                if (dataFound && dataFound.length > 0) {
                    // Reload datatable with only rows which contain searchedValue
                    this.initKtDataTable(dataFound, this.siteBLEGatewaysTable.getPageSize());
                } else {
                    // No searchedValue found => Display empty datatable
                    this.siteBLEGatewaysTable.search(this.searchedValue, "unknown");
                }
            }
        },

        onEditBLEGateway: function(gatewayId) {
            this.$router.push({
                name: "editGateway",
                params: { gatewayId: gatewayId, siteId: this.siteId }
            });
        },

        onEditBuilding: function(buildingId) {
            this.$router.push({
                name: "editBuilding",
                params: { buildingId: buildingId, siteId: this.siteId }
            });
        },

        onEditFloor: function(buildingId, floorId) {
            this.$router.push({
                name: "editFloor",
                params: {
                    siteId: this.siteId,
                    buildingId: buildingId,
                    floorId: floorId
                }
            });
        },

        registerEventsOnSiteBLEGatewaysTable() {
            var self = this;
            $("#bleGateways_datatable").on(
                "kt-datatable--on-layout-updated",
                () => {
                    $(".deleteBLEGatewayButton")
                        .off()
                        .on("click", function() {
                            let gatewayId = $(this).attr("data-gatewayid");
                            let gatewayName = $(this).attr("data-gatewayname");
                            console.log(
                                "Component(Gateways)::registerEventsOnSiteBLEGatewaysTable(deleteBLEGatewayButton) clicked. GatewayId = " +
                                    gatewayId
                            );
                            self.showDeleteModal({
                                textContent: i18n.t(
                                    "gtw_deleteGatewayConfirmText",
                                    { gatewayName: gatewayName }
                                ),
                                actionName: "DELETE_GATEWAY",
                                params: {
                                    siteId: self.siteId,
                                    gatewayId: gatewayId
                                }
                            });
                        });
                    $(".editBLEGatewayButton")
                        .off()
                        .on("click", function() {
                            let gatewayId = $(this).attr("data-gatewayid");
                            console.log(
                                "Component(Gateways)::registerEventsOnSiteBLEGatewaysTable(editBLEGatewayButton) clicked. GatewayId = " +
                                    gatewayId
                            );
                            // Hide and destroy element’s tooltip
                            $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                            // Go to edit vue
                            self.onEditBLEGateway(gatewayId);
                        });
                    $(".editBuildingButton")
                        .off()
                        .on("click", function() {
                            let buildingId = $(this).attr("data-buildingid");
                            console.log(
                                "Component(Gateways)::registerEventsOnSiteAutoCalibrationTagsTable(editBuildingButton) clicked. BuildingId = " +
                                    buildingId
                            );
                            // Hide and destroy element’s tooltip
                            $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                            // Go to edit vue
                            self.onEditBuilding(buildingId);
                        });
                    $(".editFloorButton")
                        .off()
                        .on("click", function() {
                            let buildingId = $(this).attr("data-buildingid");
                            let floorId = $(this).attr("data-floorid");
                            console.log(
                                "Component(Gateways)::registerEventsOnFloorsTable(editFloorButton) clicked. FloorId = " +
                                    floorId
                            );
                            // Hide and destroy element’s tooltip
                            $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                            // Go to edit vue
                            self.onEditFloor(buildingId, floorId);
                        });
                    // Force width pager size
                    setTimeout(() => {
                        $(".kt-datatable__pager-size").css("width", "80px");

                        // Init tooltips
                        $('[data-toggle="kt-tooltip"]').tooltip({
                            trigger: "hover"
                        });
                    }, 50);
                }
            );
        },
        openImportGatewaysTemplateXlsModal() {
            // Reset data in modal
            this.$refs.importGatewaysModal.errorDisplayed = false;
            this.$refs.importGatewaysModal.xlsData = null;
            this.$refs.importGatewaysModal.isImportButtonDisabled = true;
            this.$refs.importGatewaysModal.xlsErrors = null;
            this.$refs.importGatewaysModal.xlsFile = null;
            this.$refs.importGatewaysModal.isXlsFileIsEmpty = false;
            this.$refs.importGatewaysModal.isSheetNotFound = false;
            // Force input file empty
            $("#importGatewaysInputFile").val("");
            // Reset placeholder of label
            $("#importGatewaysInputLabel").text(i18n.t("common_chooseFile"));
            // Open modal
            this.showImportGatewaysModal();
        },
        openImportGatewaysOVXlsModal() {
            // Reset data in modal
            this.$refs.importGatewaysFromOVModal.errorDisplayed = false;
            this.$refs.importGatewaysFromOVModal.csvData = null;
            this.$refs.importGatewaysFromOVModal.isImportButtonDisabled = true;
            this.$refs.importGatewaysFromOVModal.csvErrors = null;
            this.$refs.importGatewaysFromOVModal.csvFile = null;
            this.$refs.importGatewaysFromOVModal.isCSVFileIsEmpty = false;
            this.$refs.importGatewaysFromOVModal.isSheetNotFound = false;
            // Force input file empty
            $("#importGatewaysFromOVInputFile").val("");
            // Reset placeholder of label
            $("#importGatewaysFromOVInputLabel").text(
                i18n.t("common_chooseFile")
            );
            // Open modal
            this.showImportGatewaysFromOVModal();
        },
        exportGatewaysModal() {
            // Call common vueHelper function
            var data = [];
            // Creation of table header
            var header = [
                {name: "Name", type: "STRING"},
                {name: "BLE Mac Address", type: "STRING"},
                {name: "Wifi Mac Address", type: "STRING"},
                {name: "IP Address", type: "STRING"},
                {name: "Model", type: "STRING"},
                {name: "Software Version", type: "STRING"},
                {name: "Group Name", type: "STRING"},
                {name: "Floor Elevation", type: "STRING"},
                {name: "Longitude", type: "STRING"},
                {name: "Latitude", type: "STRING"}
            ];
            this.siteBLEGateways.forEach(gateway => {
                var gatewayInfo = [gateway.name, gateway.bleMacAddress, gateway.wifiMacAddress, gateway.ipAddress, gateway.model, gateway.softwareVersion, gateway.groupName];
                // Checks if gateway is located
                if (gateway.floor) {
                    gatewayInfo.push(gateway.floorElevation);
                } else {
                    gatewayInfo.push(i18n.t("gtw_bleNotYetLocated"));
                }
                // Checks the longitude and lattitude
                if (gateway.location.hasOwnProperty("coordinates")) {
                    gatewayInfo.push(gateway.location.coordinates[0]);
                    gatewayInfo.push(gateway.location.coordinates[1]);
                } else {
                    gatewayInfo.push(i18n.t("gtw_bleNotYetLocated"));
                    gatewayInfo.push(i18n.t("gtw_bleNotYetLocated"));
                }

                // Add in export data
                data.push(gatewayInfo);
            });
            commonVueHelper.exportsDataToExcel(data, header, "gateways", "gateways");
        },
        buildDatatableData() {
            let data = [];
            if (this.siteBLEGateways && this.siteBLEGateways.length > 0) {
                for (let gateway of this.siteBLEGateways) {
                    let finalGateway = {...gateway};
                    if (gateway.building && gateway.floor) {
                        finalGateway.buildingName = gateway.building.name;
                        finalGateway.floorName = gateway.floor.name;
                    } else {
                        finalGateway.location = i18n.t("gtw_bleNotYetLocated");
                    }

                    if (gateway.updatedAt) {
                        finalGateway.formatUpdatedAt = moment(gateway.updatedAt).format( commonVueHelper.getDateStringFormatFromLocale( moment().locale() ) );
                    } else {
                        finalGateway.formatUpdatedAt = "-";
                    }
                    data.push(finalGateway);
                }
            }
            return data;
        },
        initKtDataTable(data, pageSize) {
            // Prepare datatable data
            var self = this;
            const options = {
                data: {
                    type: "local",
                    source: commonVueHelper.preventHtmlInjection(data),
                    pageSize: (pageSize ? pageSize : 20),
                    saveState: {
                        webstorage: false
                    }
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: true,
                pagination: true,
                // search: {
                //     // !!! WARNING !!! Search input text in all fields included results found in html template data-xxx
                //     input: $("#gateways_searchInput")
                // },
                columns: [
                    {
                        field: "name",
                        title: i18n.t("gtw_bleGatewayNameLabel"),
                        textAlign: "center",
                        overflow: "visible",
                        autoHide: false,
                        template: function(row) {
                            return (
                                '\
                                    <div class="kt-user-card-v2">\
                                        <div class="kt-user-card-v2__details openResourceLink">\
                                            <a class="editBLEGatewayButton kt-link kt-user-card-v2__name" data-gatewayid="' +
                                    row.id +
                                    '" href="javascript:;">' +
                                    row.name +
                                    "</a>\
                                        </div>\
                                    </div>\
                                "
                            );
                        }
                    },
                    {
                        field: "model",
                        title: i18n.t(
                            "gtw_bleGatewayModelLabel"
                        ),
                        textAlign: "center"
                    },
                    {
                        field: "bleMacAddress",
                        title: i18n.t(
                            "gtw_bleGatewayBleMacAddressLabelShort"
                        ),
                        textAlign: "center"
                    },
                    {
                        field: "updatedAt",
                        title: i18n.t("common_lastUpdateDate"),
                        sortable: "desc",
                        textAlign: "center",
                        template: function(row) {
                            return row.formatUpdatedAt;
                        }
                    },
                    {
                        field: "location",
                        title: i18n.t("gtw_bleGatewayLocation"),
                        textAlign: "center",
                        template: function(row) {
                            if (row.building && row.floor) {
                                return (
                                    `
                                            <div class="kt-user-card-v2" style="display: inline-flex;">
                                                <div class="kt-user-card-v2__details marginLandR5 openResourceLink">
                                                    <a class="editBuildingButton kt-link kt-user-card-v2__name" data-buildingid="` +
                                        row.building.id +
                                        `" href="javascript:;">` +
                                        row.buildingName +
                                        `</a>
                                                </div>
                                                /
                                                <div class="kt-user-card-v2__details marginLandR5 openResourceLink">
                                                    <a class="editFloorButton kt-link kt-user-card-v2__name" data-buildingid="` +
                                        row.building.id +
                                        `" data-floorid="` +
                                        row.floor.id +
                                        `" href="javascript:;">` +
                                        row.floorName +
                                        `</a>
                                                </div>
                                            </div>
                                        `
                                );
                            } else {
                                return row.location;
                            }
                        }
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 110,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            if (self.currentSite && self.currentSite.isInProduction) {
                                return (`
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                    i18n.t("common_edit") +
                                    `" type="button" data-gatewayid="` +
                                    row.id +
                                    `" class="btn btn-outline-brand btn-sm btn-icon editBLEGatewayButton">
                                        <i class="la la-pencil"></i>
                                    </button>
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                    i18n.t("common_unauthorizedAction") +
                                    `" type="button" data-gatewayid="` +
                                    row.id +
                                    `" data-gatewayname="` +
                                    row.name +
                                    `" class="btn btn-outline-danger btn-sm btn-icon disabled">
                                        <i class="la la-trash"></i>
                                    </button>`);
                            } else  {
                                return (`
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                    i18n.t("common_edit") +
                                    `" type="button" data-gatewayid="` +
                                    row.id +
                                    `" class="btn btn-outline-brand btn-sm btn-icon editBLEGatewayButton">
                                        <i class="la la-pencil"></i>
                                    </button>
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                    i18n.t("common_delete") +
                                    `" type="button" data-gatewayid="` +
                                    row.id +
                                    `" data-gatewayname="` +
                                    row.name +
                                    `" class="btn btn-outline-danger btn-sm btn-icon deleteBLEGatewayButton">
                                        <i class="la la-trash"></i>
                                    </button>`);
                            }
                        }
                    }
                ]
            };

            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.siteBLEGatewaysTable) {
                $("#bleGateways_datatable").KTDatatable().destroy();
                // After destroy set to null
                this.siteBLEGatewaysTable = null;
            }

            // According to source list length, show datatable or empty list icon+text
            if (options.data.source && options.data.source.length > 0) {
                // There is data => Hide empty list icon+text
                this.isEmptyList = false;
                // Now, we are ready to build the table
                this.siteBLEGatewaysTable = $("#bleGateways_datatable").KTDatatable(options);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnSiteBLEGatewaysTable();
            } else {
                // There is no data => Show empty list icon+text
                this.isEmptyList = true;
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-importgatewaysfromXLSmodal": ImportGatewaysModal,
        "app-importgatewaysfromOVmodal": ImportGatewaysFromOVModal
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
